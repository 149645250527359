import React from "react";

//displays UI for difficulty settings
//displays UI options for player symbol selection
const Selection = () => {
    return (
        <div className="selection-container">
            Selection panel
        </div>
    );
};

export default Selection;